@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    .paused {
      animation-play-state: paused;
    }
  }


  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hidden {
    -ms-overflow-style: none;  /* For Internet Explorer */
    scrollbar-width: none;  /* For Firefox */
  }